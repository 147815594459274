<template>
  <mobile-screen
    :header="true"
    screen-class="icon-app1 reception-screen gray-bg"
  >
    <template v-slot:header>
      <top-header-menu-wrapper menu-class="reception-header icon-hea1">
        <template v-slot:left>
          <home-button />
        </template>
        <div class="component-title">
          {{ displayLabelName("menu", "resources-admin", "receptions") }}
        </div>
        <template v-slot:right>
          <router-link
            v-if="!editing"
            :to="{
              name: 'r_add-reception'
            }"
          >
            <icon icon="#cx-hea1-add" />
          </router-link>
        </template>
      </top-header-menu-wrapper>
      <section class="search-wrapper applications-search">
        <div class="search-field">
          <input
            type="text"
            @keyup="searchQuery = $event.target.value"
            :placeholder="
              displayLabelName(
                'visitor-management',
                'reception-desks',
                'search'
              )
            "
          />
          <div class="icon search"><svg-icon icon="lense"></svg-icon></div>
        </div>
      </section>
      <div
        v-if="paginationData && paginationCountActive"
        class="clebex-pagination-count-wrapper"
      >
        <span class="clebex-pagination-count"
          >{{ topScreen }} - {{ bottomScreen }} /
          {{ paginationData.total }}</span
        >
      </div>
    </template>
    <div class="scroll"></div>
    <ul class="clebex-item-section pill" v-if="receptions && receptions.length">
      <li
        class="clebex-item-section-item"
        :class="{ 'full-right-underline': !editing }"
        v-for="reception in listOfReceptions"
        :key="reception.id"
      >
        <router-link
          :to="{
            name: 'r_edit-reception',
            params: { receptionId: reception.id }
          }"
          class="clebex-item-content-wrapper"
          v-if="!editing"
        >
          <span class="label">
            <span class="text">
              <span class="highlight">{{ `${reception.name}, ` }}</span>
              {{
                [reception.parent_name, reception.level_name]
                  .filter(Boolean)
                  .join(", ")
              }}
            </span>
          </span>
          <!-- <span class="label">
            <span class="highlight">{{ reception.name }}</span>
          </span> -->
          <span class="follow-up-icons">
            <span class="follow-up-icon-item">
              <icon icon="#cx-app1-information"></icon>
            </span>
          </span>
        </router-link>
        <div class="clebex-item-content-wrapper" v-else>
          <div class="checkbox tiny alt block">
            <input
              type="checkbox"
              :id="`reception-type${reception.id}`"
              name="user"
              :selected="selectedReceptions.includes(reception.id)"
              :value="reception.id"
              @change="selectReception(reception)"
            />
            <label :for="`reception-type${reception.id}`">
              <svg-icon icon="checkmark-strait"></svg-icon>
              {{ reception.name }}</label
            >
          </div>
        </div>
      </li>
    </ul>

    <template v-slot:footer>
      <nav class="actions-menu theme-gray">
        <ul class="actions-list">
          <li class="action">
            <button
              class="action-btn"
              :class="{ active: editing }"
              @click="setEditing"
            >
              {{
                displayLabelName(
                  "visitor-management",
                  "manage-reception-desks",
                  "select"
                )
              }}
            </button>
          </li>
          <li class="action" v-if="selectedReceptions.length && editing">
            <button
              class="action-btn"
              :disabled="processing"
              @click="show = true"
            >
              <icon icon="#cx-app1-delete-02-14x14" />
            </button>
            <span class="action-btn deleted-count">{{
              "(" +
                selectedReceptions.length +
                "/" +
                listOfReceptions.length +
                ")"
            }}</span>
          </li>
        </ul>
      </nav>
    </template>
    <screen-modal
      v-if="show"
      class="confirm-modal"
      type="success"
      :confirm-action="startDelete"
      :confirm-button-label="displayLabelName('global.buttons.ok')"
      :cancelButtonLabel="displayLabelName('global.buttons.cancel')"
      :show="show"
      @close="show = false"
    >
      <h3 class="modal-title">
        {{ displayLabelName("visitor-management.messages.delete-title") }}
      </h3>
      <p class="text">
        {{ displayLabelName("visitor-management.messages.delete-action-text") }}
        <br />
        {{ displayLabelName("global.messages.delete-action-question") }}
      </p>
    </screen-modal>
  </mobile-screen>
  <router-view />
</template>

<script>
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import { mapState, mapActions } from "vuex";
import paginationMixin from "@/services/mixins/pagination/pagination-mixin";

export default {
  name: "Receptions",
  mixins: [paginationMixin],
  components: {
    MobileScreen,
    TopHeaderMenuWrapper
  },
  data() {
    return {
      searchQuery: "",
      show: false,
      processing: false,
      editing: false,
      selectedReceptions: []
    };
  },
  created() {
    this.getReceptions();
    this.getLevels();
    this.getContainerAccessTypes().then(() => {
      this.getContainers();
    });
    this.$store.commit("global/setIsFullWidthScreen", true, {
      root: true
    });
  },
  computed: {
    ...mapState("reception", ["receptions", "selectedReception"]),
    listOfReceptions() {
      const query = this.searchQuery;
      if (this.receptions && this.receptions.length) {
        if (query && query.length > 1) {
          return this.receptions.filter(item =>
            item.name.toLowerCase().includes(query.toLowerCase())
          );
        } else {
          return this.receptions;
        }
      }
      return null;
    }
  },
  methods: {
    ...mapActions("reception", [
      "getReceptions",
      "getLevels",
      "getContainers",
      "setSelectedReception",
      "deleteReception",
      "getContainerAccessTypes"
    ]),
    ...mapActions("pagination", ["setPaginationData"]),
    editReception(reception) {
      this.setSelectedReception(reception);
      setTimeout(() => {
        this.$router.push({ name: this.editRouteName });
      }, 0);
    },
    selectReception(reception) {
      if (this.selectedReceptions.includes(reception.id)) {
        this.selectedReceptions.splice(
          this.selectedReceptions.indexOf(reception.id),
          1
        );
      } else {
        this.selectedReceptions.push(reception.id);
      }
    },
    setEditing() {
      this.setSelectedReception(null);
      this.editing = !this.editing;
      this.$router.push({ name: "r_reception-desks" });
    },
    async startDelete() {
      this.processing = true;
      for (var i = 0; i < this.selectedReceptions.length; i++) {
        await this.deleteReception(this.selectedReceptions[i]);
      }
      this.setPaginationData(null);
      this.processing = false;
      this.setSelectedReception(null);
      this.editing = false;
      this.selectedReceptions = [];
      this.getReceptions();
      this.$nextTick(() => {
        this.$router.push({ name: this.backLinkName });
      });
    }
  },
  beforeUnmount() {
    this.$store.commit("global/setIsFullWidthScreen", false, {
      root: true
    });
  }
};
</script>
<style>
.deleted-count {
  position: relative;
  top: -4px;
  margin: 5px 8px;
  padding-left: 0px !important;
  margin-left: 0px;
}
</style>
